<template>
  <v-card class="ma-4">
    <v-toolbar>
      <v-toolbar-title>Timeclock Usage Tracker</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-select v-model="selectedTimeclocks" :items="timeclocks" label="Timeclocks" hide-details dense multiple></v-select>
      <v-menu>
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" text>
            {{ selectedTermText }}
            <v-icon right>fal fa-chevron-down</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item v-for="{ label, term } in terms" :key="term" @click="selectedTerm = term">
            <v-list-item-title>{{ label }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-toolbar>
    <v-sheet height="800">
      <v-toolbar>
        <v-btn :disabled="months.length === 0 || currentMonth === months[0].text" text @click="prevMonth()">
          <v-icon left>fal fa-chevron-left</v-icon>
          Previous Month
        </v-btn>
        <v-spacer></v-spacer>
        <v-menu>
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" text>
              {{ currentMonth }}
              <v-icon right>fal fa-chevron-down</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item v-for="{ text, value } in months" :key="value" @click="showMonth(value)">
              <v-list-item-title>{{ text }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-spacer></v-spacer>
        <v-btn :disabled="months.length === 0 || currentMonth === months[months.length - 1].text" text @click="nextMonth()">
          Next Month
          <v-icon right>fal fa-chevron-right</v-icon>
        </v-btn>
      </v-toolbar>
      <v-calendar ref="calendar" :value="calendarDate" :events="events"></v-calendar>
    </v-sheet>
  </v-card>
</template>
<script>
import { computed, onMounted, ref, watch } from '@vue/composition-api'

export default {
  setup (props, { root }) {
    const selectedTerm = ref('')
    const terms = ref([])
    const selectedTermText = computed(() => {
      const rec = terms.value.find(({ term }) => term === selectedTerm.value)
      if (rec) return rec.label
      return ''
    })
    const timeclocks = ref([])
    const selectedTimeclocks = ref([])
    const main = ref([])
    const events = computed(() => {
      let temp = main.value
      if (selectedTimeclocks.value.length > 0) {
        // Filter for the selected timeclocks
        temp = main.value.filter(({ location }) => selectedTimeclocks.value.includes(location))
      }
      return temp.map(({ date, location, count }) => {
        return {
          name: count + ' - ' + location,
          start: date,
          end: date,
          allDay: true
        }
      })
    })

    onMounted(async () => {
      const { data } = await root.$feathers.service('system/term').find({ query: { start: { $lte: new Date() }, type: 'Traditional', $sort: { start: -1 }, $limit: 5, $select: ['term', 'label', 'start', 'end'] } })
      terms.value = data
      if (selectedTerm.value === '') {
        selectedTerm.value = terms.value[0].term
      }
      const { data: timeclockData } = await root.$feathers.service('timeclock/setup').find({ query: { $limit: 50 } })
      timeclocks.value = timeclockData.map(({ location }) => location)
    })

    async function load () {
      const rec = terms.value.find(({ term }) => term === selectedTerm.value)
      if (!rec) return
      const aggregate = [
        { $lookup: {
          from: 'Terms',
          as: 'term',
          let: { dt: '$createdAt' },
          pipeline: [
            { $match: { $expr: { $and: [
              { $lte: ['$start', '$$dt'] },
              { $gte: ['$end', '$$dt'] },
              { $eq: ['$term', selectedTerm.value] }
            ] } } }
          ]
        } },
        { $match: {
          'term.0': { $exists: true }
        } },
        { $project: {
          date: {
            $dateToString: {
              format: '%Y-%m-%d',
              date: '$createdAt'
            }
          },
          timeclock: '$in.timeclock'
        } },
        { $lookup: {
          from: 'Timeclock-Setup',
          localField: 'timeclock',
          foreignField: '_id',
          as: 'timeclock'
        } },
        { $project: {
          date: 1,
          timeclock: {
            $first: '$timeclock'
          }
        } },
        { $project: {
          date: 1,
          location: '$timeclock.location'
        } },
        { $group: {
          _id: {
            location: '$location',
            date: '$date'
          },
          count: {
            $sum: 1
          }
        } },
        { $addFields: {
          location: '$_id.location',
          date: '$_id.date'
        } },
        { $sort: {
          location: 1,
          date: 1
        } }
      ]
      console.log(JSON.stringify(aggregate))
      const { data } = await root.$feathers.service('timeclock/punch').find({ query: { aggregate } })
      main.value = data
    }

    const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
    const calendarDate = ref('')
    const calendarMin = ref('')
    const calendarMax = ref('')
    const months = ref([])
    const currentMonth = computed(() => {
      const dt = new Date(calendarDate.value)
      return monthNames[dt.getMonth()]
    })
    function prevMonth () {
      console.log('Previous Month')
      const dt = new Date(calendarDate.value)
      dt.setMonth(dt.getMonth() - 1)
      calendarDate.value = dt.toISOString().substring(0, 10)
    }
    function nextMonth () {
      console.log('Next Month')
      const dt = new Date(calendarDate.value)
      dt.setMonth(dt.getMonth() + 1)
      calendarDate.value = dt.toISOString().substring(0, 10)
    }
    function showMonth (month) {
      const rec = months.value.find(({ value }) => value === month)
      if (rec == null) return
      const dt = new Date(calendarDate.value)
      dt.setMonth(month)
      calendarDate.value = dt.toISOString().substring(0, 10)
    }

    watch(selectedTerm, () => {
      if (selectedTerm.value != null && selectedTerm.value !== '') {
        const rec = terms.value.find(({ term }) => term === selectedTerm.value)
        if (!rec) return
        load()
        const arr = []
        const dt = new Date(rec.start)
        calendarMin.value = dt.toISOString().substring(0, 10)
        const end = new Date(rec.end)
        calendarMax.value = end.toISOString().substring(0, 10)
        while (dt.getMonth() < end.getMonth()) {
          arr.push({ value: dt.getMonth(), text: monthNames[dt.getMonth()] })
          dt.setMonth(dt.getMonth() + 1)
        }
        arr.push({ value: dt.getMonth(), text: monthNames[dt.getMonth()] })
        months.value = arr
        const now = new Date()
        if (now < end) {
          calendarDate.value = now.toISOString().substring(0, 10)
        } else {
          calendarDate.value = new Date(rec.start).toISOString().substring(0, 10)
        }
      }
    })

    return {
      selectedTerm,
      terms,
      selectedTermText,
      main,
      events,
      timeclocks,
      selectedTimeclocks,
      calendarDate,
      calendarMin,
      calendarMax,
      months,
      currentMonth,
      prevMonth,
      nextMonth,
      showMonth
    }
  }
}
</script>
